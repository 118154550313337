html {
  background: black;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  height: 100%;
}
